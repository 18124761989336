import React, { useState } from 'react'
import { withAuthUser, AuthAction } from 'next-firebase-auth'
import FirebaseAuth from '../components/firebaseAuth'
import LoadingFullScreen from '../components/loadingFullScreen'
import Seo from '@/components/seo'

const Login = (props) => {
  return (
    <div className="bg-gray-50 h-screen">
      <Seo
        title="Login | Pinto Financial"
        description="Returning User? Login Here! Otherwise, click to Sign Up Now for immediate Financial Clarity in your Small Business."
      />
      <FirebaseAuth />
    </div>
  )
}

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: LoadingFullScreen,
})(Login)
