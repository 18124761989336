import React from 'react'
import LoadingSpinner from '../components/loadingSpinner'

const LoadingFullScreen = () => (
  <div>
    <div className="flex h-screen">
      <div className="m-auto">
        <LoadingSpinner color="pinto" size="8"/>
      </div>
    </div>
  </div>
)

export default LoadingFullScreen
