/* globals window */
import React, { useEffect, useState } from 'react'
import Link from 'next/link'
//import Image from 'next/image'
import firebase from '../utils/firebase'
import LoadingSpinner from '../components/loadingSpinner'
import Image from 'next/image'

const FirebaseAuth = () => {
  const [ renderAuth, setRenderAuth ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const signIn = (event, email, password) => {
    setLoading(true);
    event.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((signedInUser) => {
        // Signed in
        setLoading(false);
      })
      .catch((error) => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        // ..
        //setValues({ ...values, errors: errorMessage });
        setLoading(false);
      });
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRenderAuth(true)
    }
  }, [])
  return (
    <div>
      {renderAuth ? (
        <>
          <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
              <Link href="/" passHref>
                <div className="h-auto w-20 cursor-pointer">
                  <Image
                    width={80}
                    height={29}
                    src="/pinto-logo.webp"
                    alt="Pinto Logo"
                    unoptimized
                  />
                </div>
              </Link>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="bg-white py-12 px-12 shadow sm:rounded-lg">
                <h2 className="text-2xl mb-6 text-gray-900">Sign in to your account</h2>
                <form className="space-y-8" onSubmit={(e) => signIn(e, email, password)}>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pinto focus:border-pinto sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pinto focus:border-pinto sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    {/*<div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        Remember me
                      </label>
                    </div>
                    */}
                    <div className="text-sm cursor-pointer">
                      <Link href="/forgot-password">
                        <span className="font-medium text-blue-600 hover:text-blue-500 ">
                          Forgot your password?
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pinto hover:bg-pinto-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      {loading ? <LoadingSpinner />:'Sign in'}
                    </button>
                  </div>
                </form>

              </div>
              <p className="mt-8 mb-6 text-sm text-gray-600">
                Don&apos;t have an account?{' '}
                <span className="font-medium text-blue-600 hover:text-blue-500">
                  <Link href="/register">
                  Sign up for free account
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default FirebaseAuth
